import { React, useState, useCallback, useEffect, StatePool } from '../../../imports/import';
import {LandTitleStyle} from '../../../imports/import-classes';
import {MyInput, EditText} from '../../../imports/import-components';

const FontElementData = StatePool.createState({});

const LandTitle = ({ onTextData, onChange, titleText }) => {
    const [objTitleParameters, setObjTitleParameters] = useState(null);
    const [saveStateEditText, setSaveStateEditText] = StatePool.useState(FontElementData);

    const handleDataTitleObj = useCallback((objectDataTitle) => {
        const titleData = { titleTextData: objectDataTitle };
        setSaveStateEditText(titleData);
        onTextData(titleData);
        // eslint-disable-next-line
    }, [objTitleParameters, onTextData]);

    useEffect(() => {
        if (objTitleParameters) {
            onChange(objTitleParameters);
        }
    }, [handleDataTitleObj, onTextData, saveStateEditText, objTitleParameters]);

    return (
        <div className="tabsContentContainer">
            <div className={LandTitleStyle.siteTitleSlogan}>
                <p className="littleTitleSlogan">Текcт заголовка</p>
                <div className={LandTitleStyle.siteTitleSlogan__inputBlock}>
                    <MyInput
                        placeholder="Ваш заголовок"
                        onChange={(event) => onChange(event.target.value)}
                        value={titleText}
                    />
                </div>

                <EditText onTextData={onTextData} />
            </div>
        </div>
    );
};

export default LandTitle;
