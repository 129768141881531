import React from 'react';

const AddTextToWheel = ({textWheelData, setSectionWheel, colorText, fontSizeTextWheel}) => {
    const wheel_6_section = ['rotate(-30deg) translate(85px) rotate(180deg)',
                            'rotate(30deg) translate(85px) rotate(180deg)',
                            'rotate(90deg) translate(85px) rotate(180deg)',
                            'rotate(150deg) translate(85px) rotate(180deg)',
                            'rotate(210deg) translate(85px) rotate(180deg)',
                            'rotate(270deg) translate(85px) rotate(180deg)'];
    const wheel_8_section = ['rotate(22.5deg) translate(85px) rotate(180deg)',
                            'rotate(67.5deg) translate(85px) rotate(180deg)',
                            'rotate(112.5deg) translate(85px) rotate(180deg)',
                            'rotate(157.5deg) translate(85px) rotate(180deg)',
                            'rotate(202.5deg) translate(85px) rotate(180deg)',
                            'rotate(247.5deg) translate(85px) rotate(180deg)',
                            'rotate(292.5deg) translate(85px) rotate(180deg)',
                            'rotate(337.5deg) translate(85px) rotate(180deg)'];
    const wheel_12_section = ['rotate(15deg) translate(85px) rotate(180deg)',
                            'rotate(45deg) translate(85px) rotate(180deg)',
                            'rotate(75deg) translate(85px) rotate(180deg)',
                            'rotate(105deg) translate(85px) rotate(180deg)',
                            'rotate(135deg) translate(85px) rotate(180deg)',
                            'rotate(165deg) translate(85px) rotate(180deg)',
                            'rotate(195deg) translate(85px) rotate(180deg)',
                            'rotate(225deg) translate(85px) rotate(180deg)',
                            'rotate(255deg) translate(85px) rotate(180deg)',
                            'rotate(285deg) translate(85px) rotate(180deg)',
                            'rotate(315deg) translate(85px) rotate(180deg)',
                            'rotate(345deg) translate(85px) rotate(180deg)'];
    const wheelTextContent = () => {
        const itemTextWheelSection = [];
        if (textWheelData.length > 0) {
            textWheelData.forEach((element, key) => {
                let styleData =  setSectionWheel === 6 ? wheel_6_section[key] :
                    setSectionWheel === 8 ? wheel_8_section[key] :
                        setSectionWheel === 12 ? wheel_12_section[key] : wheel_6_section[key];
                itemTextWheelSection.push(
                    <div key={key} className="text__section"
                         style={{ transform: styleData }} >
                        <p style={{color: colorText, fontSize: fontSizeTextWheel+'px'}}>{element.valueData !== undefined ? element.valueData : ''}</p>
                    </div>
                )
            })
        }
        return itemTextWheelSection;
    }

    return (
        <div className="wheelTextList" id="wheel_list">
            {wheelTextContent()}
        </div>
    );
};

export default AddTextToWheel;