import {React, useState, useEffect, StatePool, Select, useSearchParams } from '../../../imports/import';
import {LandTopicStyle} from '../../../imports/import-classes';
import LandTopicPreviewImages from './LandTopicPreviewImages.json';
import {PaginatedItems} from '../../../imports/import-components'
import {State} from "state-pool";

const SelectListItem = StatePool.createState('');
const SelectListObject = StatePool.createState(undefined);
const SelectedNameItem = StatePool.createState(null);
const TypeLand = StatePool.createState('binom');
const FullOfferOneLink = StatePool.createState('');
const CheckUTMParamLink = StatePool.createState('show');


const LandTopic = ({
                       getNameLandPreview,
                       setTypeLandUpload,
                       getUtmLinkOneLink,
                       getTypeGenerateLand
                   }) => {
    const [UTMParams, setUTMParam] = useSearchParams();
    const [oneLinkOfferLink, setOneLinkOfferLink] = StatePool.useState(FullOfferOneLink);
    const [typeLandCreate, setTypeLandCreate] = StatePool.useState(TypeLand);
    const [checkUTMParam, setCheckUTMParam] = StatePool.useState(CheckUTMParamLink);

    useEffect(() => {
        if (UTMParams.get("user_id")) {
            let objectParam = {
                'user_id': UTMParams.get('user_id'),
                'land_id': UTMParams.get('land_id'),
                'btn_url': `${UTMParams.get('btn_url')}&campaign=${UTMParams.get('campaign')}&pixel_id=${UTMParams.get('pixel_id')}&pid=${UTMParams.get('pid')}`
            };
            setOneLinkOfferLink(JSON.stringify(objectParam));
            getUtmLinkOneLink(oneLinkOfferLink);
            setCheckUTMParam('hide');
        }
        getTypeGenerateLand(checkUTMParam);
        setTypeLandUpload(typeLandCreate);
        console.log(oneLinkOfferLink);
    }, [typeLandCreate, oneLinkOfferLink])
    const [selectTopicValue, setSelectTopicValue] = StatePool.useState(SelectedNameItem);
    const [listPreviewTopic, setListPreviewTopic] = StatePool.useState(SelectListObject);
    const [listPaginationImage, setListPaginationImage] = useState(null);
    const [selectedOptionItem, setSelectedOptionItem] = StatePool.useState(SelectListItem);
    const handleSelectTopicValue = (selected) => {
        if (selected && selected.value !== undefined) {
            setSelectedOptionItem(selected.value);
            setSelectTopicValue(selected);
            getListTopicLandPreview(SelectListItem.value);
        }
    }

    const getListTopicLandPreview = (selectTopicValue) => {
        if (SelectListItem.value !== null) {
            let listImages = [];
            let objectSelectTopic = undefined;
            for (let item in listPreviewTopic) {
                if (item === selectTopicValue) {
                    objectSelectTopic = item;
                }
            }

            if (objectSelectTopic !== undefined) {
                for (let lands in listPreviewTopic[objectSelectTopic]) {
                    console.log(lands)
                    let landsPreviewData = {};
                    landsPreviewData["preview"] = listPreviewTopic[objectSelectTopic][lands].preview;
                    landsPreviewData["dataLandNumerical"] = listPreviewTopic[objectSelectTopic][lands].dataLandNumerical;
                    listImages.push(landsPreviewData);
                }
            }

            setListPaginationImage(listImages);
        } else {
            setListPaginationImage([]);
        }
    }

    useEffect(() => {
        // This code will execute after selectedOptionItem has been updated
        if (selectedOptionItem !== null) {
            getListTopicLandPreview(selectedOptionItem);
        }
    }, [selectedOptionItem]);

    const options = [
        { value: 'egypt', label: 'Єгипет' },
        { value: 'wolf_bufalo', label: 'Вовки, буфало' },
        { value: 'diamond_fruits', label: 'Діаманти, фрукти' },
        { value: 'bonanza', label: 'Світ бонанза' },
        { value: 'leprechauns', label: 'Леприкони' },
        { value: 'plinko', label: 'Плінко' },
        { value: 'thimbles', label: 'Thimbles' },
        { value: 'jungle', label: 'Джунглі' },
        { value: 'zeus', label: 'Зевс' },
        { value: 'sport', label: 'Спорт' },
        { value: 'joker', label: 'Джокер, 777' },
        { value: 'tiger', label: 'Тигр' },
        { value: 'kokoko', label: 'Курка' },
    ];

    const customStyleSelect = {
        control: (provided, state) => ({
            ...provided,
            background: '#262626',
            borderColor: '#363738',
            outline: "none",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#262626",
            color: 'red',
        }),
        options: (provided, state) => ({
            ...provided,
            color: state.isFocused ? 'red' : 'orange',
            backgroundColor: state.isFocused ? 'gray' : 'white',
        })
    }

    const handleGetLandData = (data) => {
        getNameLandPreview(data);
    }

    useEffect(() => {
        setListPreviewTopic(LandTopicPreviewImages);
    }, [])

    return (
        <div className="tabsContentContainer">
            <div className={LandTopicStyle.selectBlock}>
                <p className={LandTopicStyle.littleTitle}>Тематика ленда</p>
                <Select
                    styles={customStyleSelect}
                    options={options}
                    onChange={handleSelectTopicValue}
                    value={selectTopicValue}
                    />
            </div>
            <div className={LandTopicStyle.topicLandPagination} id="PaginationPageList">
                <p className={LandTopicStyle.littleTitle}>Приклади лендів</p>
                <PaginatedItems
                    getImagePreviewList={listPaginationImage}
                    nameDataPreviewLand={handleGetLandData}
                    itemsPerPage={4}
                />
            </div>
        </div>
    );
};

export default LandTopic;
