import {React, useEffect, StatePool} from '../../../imports/import';
import {TitleElement, MyInput, EditText} from '../../../imports/import-components'
import {LandButtonStyle} from '../../../imports/import-classes';

const ButtonText = StatePool.createState('');

const LandButton = ({
                        onChangeTextButton,
                        onButtonData,
                        titleData
}) => {
    const [buttonText, setButtonText] = StatePool.useState(ButtonText);
    const handleTextButton = (data) => {
        setButtonText(data);
    }

    useEffect(() => {
        onChangeTextButton(buttonText)
    }, [buttonText]);

    return (
        <div className="tabsContentContainer">
            <div className={LandButtonStyle.inputBlock}>
                <TitleElement titleText="Текст кнопки прокруту" />
                <MyInput placeholder="Крутити" onChange={
                    (event) => {
                        handleTextButton(event.target.value);
                    }
                }

                value={buttonText} />
            </div>
            <EditText
                onTextData={onButtonData}
                hidePositionText={true}
                dataSave={titleData}
            />
        </div>
    );
};

export default LandButton;