import React from 'react';
import classes from './titleElement.module.scss';

const TitleElement = ({titleText}) => {
    return (
        <p className={classes.blockTitle}>
            {titleText}
        </p>
    );
};

export default TitleElement;