import {React} from '../../imports/import';
import {classesErrorHandler} from '../../imports/import-classes';
const ErrorHandler = ({statusDisplay, messageError}) => {
    return (
        <div className={classesErrorHandler.ContainerError}
             // style={{display: statusDisplay === 'show' ? 'block' : 'none'}}
        >
            <p className={classesErrorHandler.TitleError}>Помилка</p>
            <p className={classesErrorHandler.ErrorBody}>{messageError}</p>
        </div>
    );
};

export default ErrorHandler;