import React, {useState} from 'react';
import ColorPicker from "../ColorPicker/ColorPicker";
import classes from './EditText.module.scss';
import TextBold from './images/bold.png';
import TextItalic from './images/italic.png';
import TextCaps from './images/caps.png';
import TextUnderline from './images/underline.png';
import TextLeftSide from './images/leftSide.png';
import TextLeftSideActive from './images/leftSide_active.png';
import TextRightSide from './images/rightSide.png';
import TextRightSideActive from './images/rightSide_active.png';
import TextCenterSide from './images/center.png';
import TextCenterSideActive from './images/center_active.png';
// import Select from 'react-select'
import MyInput from "../Input/MyInput";
import TitleElement from "../TitleElement/TitleElement";

const EditText = ({
                      onTextData,
                      hidePositionText
}) => {
    const [fontSize, setFontSize] = useState(0);
    const [fontWeight, setFontWeight] = useState('unset');
    const [colorText, setColorText] = useState('#ffffff');
    const [styleText, setStyleText] = useState('unset');
    const [textUppercaseStyle, setTextUppercaseStyle] = useState('');
    const [textUnderLineStyle, setTextUnderLineStyle] = useState('');
    const [positionText, setPositionText] = useState('center');

    const handleFontSize = (event) => {
        const fontSizeValue = parseInt(event.target.value, 10);
        setFontSize(fontSizeValue);
        const titleDataParams = {
            fontSize: fontSizeValue,
            colorText,
            styleText,
            textUppercase: textUppercaseStyle,
            textUnderline: textUnderLineStyle,
            positionText,
        };
        onTextData(titleDataParams);
    };

    const handleColorText = (data) => {
        setColorText(data);
        const titleDataParams = {
            fontSize,
            colorText: data,
            styleText,
            textUppercase: textUppercaseStyle,
            textUnderline: textUnderLineStyle,
            positionText,
        };
        onTextData(titleDataParams);
    }

    const handleTextWeight = (event) => {
        const selectElementWeight = event.target.parentNode.dataset.style;
        let parentNodeClick = event.target.parentNode;
        let sendStyleText = '';
        parentNodeClick.classList.toggle(`${classes.active}`);
        if (parentNodeClick.classList.contains(`${classes.active}`)) {
            sendStyleText = selectElementWeight;
        } else {
            sendStyleText = 'unset';
        }
        setFontWeight(sendStyleText);
        const titleDataParams = {
            fontSize,
            colorText,
            fontWeight,
            styleText: sendStyleText,
            textUppercase: textUppercaseStyle,
            textUnderline: textUnderLineStyle,
            positionText,
        };
        onTextData(titleDataParams);
    }

    const handleTextStyle = (event) => {
        const selectElement = event.target.parentNode.dataset.style;
        let parentNodeClick = event.target.parentNode;
        let sendStyleText = '';
        parentNodeClick.classList.toggle(`${classes.active}`);
        if (parentNodeClick.classList.contains(`${classes.active}`)) {
            sendStyleText = selectElement;
        } else {
            sendStyleText = 'unset';
        }
        setStyleText(sendStyleText);
        const titleDataParams = {
            fontSize,
            colorText,
            fontWeight,
            styleText: sendStyleText,
            textUppercase: textUppercaseStyle,
            textUnderline: textUnderLineStyle,
            positionText,
        };
        onTextData(titleDataParams);
    }

    const handleTextDecoration = (event) => {
        const selectUnderline = event.target.parentNode.dataset.style;
        let parentNodeClick = event.target.parentNode;
        let sendStyleDecoration = '';
        parentNodeClick.classList.toggle(`${classes.active}`);
        if (parentNodeClick.classList.contains(`${classes.active}`)) {
            sendStyleDecoration = selectUnderline;
        } else {
            sendStyleDecoration = 'unset';
        }
        setTextUnderLineStyle(sendStyleDecoration);
        const titleDataParams = {
            fontSize,
            fontWeight,
            colorText,
            styleText,
            textUppercase: textUppercaseStyle,
            textUnderline: sendStyleDecoration,
            positionText,
        };
        onTextData(titleDataParams);
    }

    const handleTextStyleUppercase = (event) => {
        let parentNodeClick = event.target.parentNode;
        let selectElement = event.target.parentNode.dataset.style;
        let sendStyleUppercase = '';
        parentNodeClick.classList.toggle(`${classes.active}`);
        if (parentNodeClick.classList.contains(`${classes.active}`)) {
            sendStyleUppercase = selectElement;
        } else {
            sendStyleUppercase = 'unset';
        }

        setTextUppercaseStyle(sendStyleUppercase);
        const titleDataParams = {
            fontSize,
            fontWeight,
            colorText,
            styleText,
            textUppercase: sendStyleUppercase,
            textUnderline: textUnderLineStyle,
            positionText,
        };
        onTextData(titleDataParams);
    }

    const handlePositionText = (event) => {
        const selectElement = event.target.parentNode.dataset.position;
        const parentElement = event.target.parentNode;
        const listItemNodes = parentElement.parentNode.childNodes;
        setPositionText(selectElement);
        const titleDataParams = {
            fontSize,
            colorText,
            fontWeight,
            styleText,
            textUppercase: textUppercaseStyle,
            textUnderline: textUnderLineStyle,
            positionText: selectElement,
        };
        onTextData(titleDataParams);
        for (let item of listItemNodes) {
            item.classList.remove(`${classes.active}`);
        }
        parentElement.classList.add(`${classes.active}`);
    }

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ];

    return (
        <div className={classes.EditTextItem}>
            <div className={`${classes.TextSize} ${classes.item}`}>
                <TitleElement titleText="Розмір тексту" />
                <MyInput
                    placeholder="32"
                    onChange={handleFontSize}
                />
            </div>
            <div className={`${classes.colorText} ${classes.item}`}>
                <TitleElement titleText="Колір тексту" />
                <ColorPicker getColor={handleColorText} />
            </div>
            <div className={`${classes.fontStyle} ${classes.item}`}>
                <TitleElement titleText="Стиль" />
                <ul className={classes.listFontType}>
                    <li
                        className={`${classes.listFontType__item} `}
                        onClick={handleTextWeight}
                        data-style="bold" >
                        <img src={TextBold} alt="Bold"/>
                    </li>
                    <li
                        className={`${classes.listFontType__item} `}
                        onClick={handleTextStyle}
                        data-style="italic" >
                        <img src={TextItalic} alt="Italic"/>
                    </li>
                    <li
                        className={`${classes.listFontType__item}`}
                        onClick={handleTextStyleUppercase}
                        data-style="uppercase" >
                        <img src={TextCaps} alt="CapsLock"/>
                    </li>
                    <li
                        className={`${classes.listFontType__item} `}
                        onClick={handleTextDecoration}
                        data-style="underline" >
                        <img src={TextUnderline} alt="Underline"/>
                    </li>
                </ul>
            </div>
            <div
                className={`${classes.textPosition} ${classes.item}`}
                style={{display: hidePositionText ? 'none' : 'block'}}>
                <TitleElement titleText="Розміщення" />
                <ul className={classes.textPositionList}>
                    <li
                        className={`${classes.textPositionList__item}`} onClick={handlePositionText}
                        data-position="left" >
                        <img
                            src={TextLeftSide}
                            className={classes.EditText_PositionTextSideImage} alt="Left"/>
                        <img
                            src={TextLeftSideActive}
                            className={classes.EditText_PositionTextSideImage__Active}
                            alt="Left"/>
                    </li>
                    <li className={`${classes.textPositionList__item}`}
                        onClick={handlePositionText}
                        data-position="center" >
                        <img
                            src={TextCenterSide}
                            className={classes.EditText_PositionTextSideImage}
                            alt=""/>
                        <img
                            src={TextCenterSideActive}
                            className={classes.EditText_PositionTextSideImage__Active}
                            alt=""/>
                    </li>
                    <li
                        className={`${classes.textPositionList__item}`}
                        onClick={handlePositionText}
                        data-position="right" >
                        <img
                            src={TextRightSide}
                            className={classes.EditText_PositionTextSideImage}
                            alt=""/>
                        <img
                            src={TextRightSideActive}
                            className={classes.EditText_PositionTextSideImage__Active}
                            alt=""/>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default EditText;