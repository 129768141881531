import React, {useEffect} from 'react';
import Land_1 from './Land_1/land_1';
import Land_2 from './Land_2/land_2';
import Land_3 from './Land_3/land_3';
import Land_4 from './Land_4/land_4';
import Land_5 from './Land_5/land_5';
import Land_6 from './Land_6/land_6';
import Land_7 from './Land_7/land_7';
import Land_8 from './Land_8/land_8';
import Land_9 from './Land_9/land_9';
import Land_10 from './Land_10/land_10';
import Land_11 from './Land_11/land_11';
import Land_12 from './Land_12/land_12';
import Land_13 from './Land_13/land_13';
import Land_14 from './Land_14/land_14';
import Land_15 from './Land_15/land_15';
import Land_16 from './Land_16/land_16';
import Land_17 from './Land_17/land_17';
import Land_18 from './Land_18/land_18';
import Land_19 from './Land_19/land_19';
import Land_20 from './Land_20/land_20';
import Land_21 from './Land_21/land_21';
import Land_22 from './Land_22/land_22';
import Land_23 from './Land_23/land_23';
import Land_24 from './Land_24/land_24';
import Land_25 from './Land_25/land_25';
import Land_26 from './Land_26/land_26';
import Land_27 from './Land_27/land_27';
import Land_28 from './Land_28/land_28';
import Land_29 from './Land_29/land_29';
import Land_30 from './Land_30/land_30';
import Land_31 from './Land_31/land_31';
import Land_32 from './Land_32/land_32';
import Land_33 from './Land_33/land_33';
import Land_34 from './Land_34/land_34';
import Land_35 from './Land_35/land_35';



const SendSelectLand = ({landName, styleLand, rerenderSitePreview, sendRender}) => {
    let selectedComponent;
    switch (landName) {
        case 'Land_1':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_1 styleLandObject={styleLand} />;
            break;
        case 'Land_2':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_2 styleLandObject={styleLand} />;
            break;
        case 'Land_3':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_3 styleLandObject={styleLand} />;
            break;
        case 'Land_4':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_4 styleLandObject={styleLand} />;
            break;
        case 'Land_5':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_5 styleLandObject={styleLand} />;
            break;
        case 'Land_6':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_6 styleLandObject={styleLand} />;
            break;
        case 'Land_7':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_7 styleLandObject={styleLand} />;
            break;
        case 'Land_8':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_8 styleLandObject={styleLand} />;
            break;
        case 'Land_9':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_9 styleLandObject={styleLand} />;
            break;
        case 'Land_10':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_10 styleLandObject={styleLand} />;
            break;
        case 'Land_11':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_11 styleLandObject={styleLand} />;
            break;
        case 'Land_12':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_12 styleLandObject={styleLand} />;
            break;
        case 'Land_13':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_13 styleLandObject={styleLand} />;
            break;
        case 'Land_14':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_14 styleLandObject={styleLand} />;
            break;
        case 'Land_15':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_15 styleLandObject={styleLand} />;
            break;
        case 'Land_16':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_16 styleLandObject={styleLand} />;
            break;
        case 'Land_17':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_17 styleLandObject={styleLand} />;
            break;
        case 'Land_18':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_18 styleLandObject={styleLand} />;
            break;
        case 'Land_19':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_19 styleLandObject={styleLand} />;
            break;
        case 'Land_20':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_20 styleLandObject={styleLand} />;
            break;
        case 'Land_21':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_21 styleLandObject={styleLand} />;
            break;
        case 'Land_22':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_22 styleLandObject={styleLand} />;
            break;
        case 'Land_23':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_23 styleLandObject={styleLand} />;
            break;
        case 'Land_24':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_24 styleLandObject={styleLand} />;
            break;
        case 'Land_25':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_25 styleLandObject={styleLand} />;
            break;
        case 'Land_26':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_26 styleLandObject={styleLand} />;
            break;
        case 'Land_27':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_27 styleLandObject={styleLand} />;
            break;
        case 'Land_28':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_28 styleLandObject={styleLand} />;
            break;
        case 'Land_29':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_29 styleLandObject={styleLand} />;
            break;
        case 'Land_30':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_30 styleLandObject={styleLand} />;
            break;
        case 'Land_31':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_31 styleLandObject={styleLand} />;
            break;
        case 'Land_32':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_32 styleLandObject={styleLand} />;
            break;
        case 'Land_33':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_33 styleLandObject={styleLand} />;
            break;
        case 'Land_34':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_34 styleLandObject={styleLand} />;
            break;
        case 'Land_35':
            // eslint-disable-next-line react/jsx-pascal-case
            selectedComponent = <Land_35 styleLandObject={styleLand} />;
            break;
        default:
            selectedComponent = null;
            break;
    }

    useEffect(()=>{
        // console.log('rerenderSitePreview змінився', rerenderSitePreview);

    }, [rerenderSitePreview])

    return (
        <div key={rerenderSitePreview} style={{width: '100%', maxWidth: '350px'}}>
            {selectedComponent}
        </div>
    );
};

export default SendSelectLand;