import {React, useEffect, useState, axios } from '../../imports/import';
import {SendSelectLand, ErrorHandler} from '../../imports/import-components'
import {ClassesPreviewSite} from '../../imports/import-classes';
import {DownloadSiteButton, RefreshPage} from '../../imports/import-images';

const CreateLand = ({
                        topicDataNameLand,
                        logoData,
                        setPageLogoPosition,
                        titleText,
                        titleTextPosition,
                        siteTitleData,
                        setCountSection,
                        sectionWheelText,
                        setNewWheel,
                        textButtonLand,
                        onButtonData,
                        popupTitle,
                        popupSubTitle,
                        popupButtonText,
                        popupTextData,
                        colorTextWheel,
                        linkToOffer,
                        oneLinkOffer,
                        setWheelTextFontSize,
                        typeTrackerSelected,
                        nameArchiveLand,
                        checkLandGenerate
}) => {
    const UTMParamOneLink = oneLinkOffer ? JSON.parse(oneLinkOffer) : '';
    const objectStyleLand = {
        "siteLogo" : logoData === null ? undefined : logoData,
        "positionLogo": setPageLogoPosition,
        "titleText": titleText,
        "titlePosition": titleTextPosition,
        "siteTitle": siteTitleData,
        "textButton": textButtonLand,
        "buttonTextData": onButtonData,
        "textTitlePopup": popupTitle,
        "textSubTitlePopup": popupSubTitle,
        "textButtonPopup": popupButtonText,
        "dataPopupText": popupTextData,
        "sectionWheelDataText": sectionWheelText,
        "countSectionWheel": setCountSection,
        "getNewWheel": setNewWheel,
        "colorTextWheel": colorTextWheel,
        "offerLink": oneLinkOffer ? UTMParamOneLink.btn_url : `https://${linkToOffer}/click.php?lp=1`,
        "wheelTextFontSize": setWheelTextFontSize,
        "typeTracker": !typeTrackerSelected ? 'link' : typeTrackerSelected,
        "oneLink": oneLinkOffer ? 'onelink' : 'tracker',
        "archiveName": !nameArchiveLand ? UTMParamOneLink.user_id : nameArchiveLand,
        "checkLandGenerate": checkLandGenerate,
        "land_id": !nameArchiveLand ? UTMParamOneLink.land_id : ''
    }
    console.log(objectStyleLand.offerLink, 'archive  name');
    // console.log(objectStyleLand.typeTrackerSelected);
    // const saveToSessionStorage = () => {
    //     sessionStorage.setItem('objectStyleLand', JSON.stringify(objectStyleLand));
    // }
    // const [checkErrorObject, setCheckErrorObject] = useState();
    const [statusSend, setStatusSend] = useState();
    const [errorMessageText, setErrorMessageText] = useState();
    const [showBlock, setShowBlock] = useState(false);
    const [rerenderComponent, setRerenderComponent] = useState(0);
    // const [successLoad, setSuccessLoad] = useState()

    const handleRerenderComponent = () => {
        setRerenderComponent(rerenderComponent + 1);
    };
    const dataStyleToDownload = {};
    useEffect(() => {
        if (topicDataNameLand) {
            dataStyleToDownload["landName"] = topicDataNameLand;
        }
        // eslint-disable-next-line
    }, [topicDataNameLand, objectStyleLand]);
    const jsonFormatStyleData = JSON.stringify(objectStyleLand);
    const dataToSubmit = {
        landName: topicDataNameLand,
        objectStyleLandData: jsonFormatStyleData
    };
    useEffect(() => {
        if (statusSend === 'ok') {
            axios.post('https://api.lpconstructor.com/main', dataToSubmit)
            // axios.post('http://localhost:8000/main', dataToSubmit)
                .then(response => {
                    const archiveUrl = `https://api.lpconstructor.com/main/archives??landName=${topicDataNameLand}&archiveName=${objectStyleLand.archiveName}&oneLink=${checkLandGenerate}&typeTracker=${!typeTrackerSelected ? 'link' : typeTrackerSelected}&landId=${UTMParamOneLink ? UTMParamOneLink.land_id : 'null'}&userId=${UTMParamOneLink ? UTMParamOneLink.user_id : 'null'}`;
                    // const archiveUrl = `http://localhost:8000/main/archives?landName=${topicDataNameLand}&archiveName=${objectStyleLand.archiveName}&oneLink=${checkLandGenerate}&typeTracker=${!typeTrackerSelected ? 'link' : typeTrackerSelected}&landId=${UTMParamOneLink ? UTMParamOneLink.land_id : 'null'}&userId=${UTMParamOneLink ? UTMParamOneLink.user_id : 'null'}`;
                    const a = document.createElement('a');
                    a.href = archiveUrl;
                    a.download = `${topicDataNameLand}.zip`;
                    document.body.appendChild(a);
                    a.click();
                    setStatusSend('error');
                })
                .catch(error => {
                    console.error('Помилка при відправці запиту: ', error);
                });
        }
    }, [statusSend, dataToSubmit, topicDataNameLand]);

    const sendDataStyleToBack = (event) => {
        event.preventDefault();
        if (!objectStyleLand.titleText) {
            setErrorMessageText('Додайте заголовок');
            setStatusSend('error');
            setShowBlock(true);
            return false;
        }

        if (!objectStyleLand.getNewWheel) {
            setErrorMessageText('Виберіть колесо');
            setStatusSend('error');
            setShowBlock(true);
            return false;
        }

        if (!objectStyleLand.textButton) {
            setErrorMessageText('Додайте текст на кнопку');
            setStatusSend('error');
            setShowBlock(true);
            return false;
        }

        if (!objectStyleLand.textButtonPopup) {
            setErrorMessageText('Додайте текст на кнопку в попапі');
            setStatusSend('error');
            setShowBlock(true);
            return false;
        }

        if (!objectStyleLand.textTitlePopup) {
            setErrorMessageText('Додайте текст title на попапі');
            setStatusSend('error');
            setShowBlock(true);
            return false;
        }
        setStatusSend('ok');
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (statusSend === 'error') {
                setShowBlock(false);
            }
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, [statusSend, showBlock]);

    // useEffect(() => {
    //     saveToSessionStorage();
    //     const storedObject = JSON.parse(sessionStorage.getItem('objectStyleLand'));
    //     console.log(storedObject);
    // }, [objectStyleLand])
    return (
        <div className={ClassesPreviewSite.ContainerPreview}>
            {statusSend === 'error' && showBlock &&
                        <ErrorHandler
                            statusDisplay={'show'}
                            messageError={errorMessageText}
                        />

            }

            <div className={ClassesPreviewSite.stickyBlockPreview}>
                <p className={ClassesPreviewSite.TextPreview}>Preview site generate</p>
                <div className={ClassesPreviewSite.buttonDownload} onClick={sendDataStyleToBack}>
                    <img src={DownloadSiteButton} alt=""/>
                </div>
                <div
                    className={ClassesPreviewSite.RerenderElement}
                    onClick={handleRerenderComponent} >
                    <img src={RefreshPage} alt=""/>
                </div>
            </div>
            <SendSelectLand
                landName={topicDataNameLand}
                styleLand={objectStyleLand}
                rerenderSitePreview={rerenderComponent}
            />
        </div>
    );
};

export default CreateLand;

