import {React} from '../../imports/import';
import {LanguageList} from '../../imports/import-components';
import {MainLogo} from '../../imports/import-images';
import {ClassesHeader} from '../../imports/import-classes';


const HeaderComponents = () => {
    return (
        <div className={ClassesHeader.MyHeader}>
            <div className={`${ClassesHeader.MyHeaderContainer}`}>
                <div className={ClassesHeader.MyHeader_logo}>
                    <a
                        href="#" className={ClassesHeader.MyHeader_logoLink}>
                        <img
                            src={MainLogo}
                            className={ClassesHeader.MyHeader_logoImg}
                            alt=""/>
                    </a>
                </div>

                <div className="lang">
                    <ul className="lang__list">
                        <LanguageList>UA</LanguageList>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default HeaderComponents;