import {React, useEffect, useState } from '../../../imports/import';
import ReactPaginate from 'react-paginate';
import classes from "./PaginationTopic.module.scss";
import ReactDOM from 'react-dom';

function Items({ currentItems, onNameLandDataClick }) {
    return (
        <div className={classes.ItemList}>
            {currentItems && currentItems.map((item, index) => (
                <div className={classes.ElementPaginationList} key={index}>
                    <img
                        key={item.preview}
                        src={require(`../../../assets/${item.preview}`)}
                        alt=""
                        data-nameland={item.dataLandNumerical}
                        onClick={() => onNameLandDataClick(item.dataLandNumerical)}
                    />
                </div>
            ))}
        </div>
    );
}

export function PaginatedItems({
                                   getImagePreviewList,
                                   itemsPerPage,
                                   nameDataPreviewLand
}) {
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        if (getImagePreviewList) {
            const endOffset = itemOffset + itemsPerPage;
            const slicedItems = getImagePreviewList.slice(itemOffset, endOffset);
            // console.log(itemOffset, itemsPerPage)
            setCurrentItems(slicedItems);
            setPageCount(Math.ceil(getImagePreviewList.length / itemsPerPage));
        }
    }, [getImagePreviewList, itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % getImagePreviewList.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <Items currentItems={currentItems} onNameLandDataClick={nameDataPreviewLand} />
            <ReactPaginate
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="PaginationPageList"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </>
    );
}
const container = document.getElementById('PaginationPageList');
if (container) {
    ReactDOM.render(
        <PaginatedItems itemsPerPage={4} />,
        container
    );
}
