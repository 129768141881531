import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import ReactDOM from 'react-dom';

function Items({ currentItems, getUrlSelectItem }) {
    const handleSetSelectImageUrl = (event) => {
        const imageUrl = event.target.dataset.url_image;
        getUrlSelectItem(imageUrl);
    };
    return (
        <div className="ItemList">
            {currentItems && currentItems.map((item, index) => (
                <div className="ElementPaginationList" key={index}>
                    <img key={item}
                         src={require(`../../../assets/${item}`)}
                         alt=""
                         data-url_image={item}
                         onClick={handleSetSelectImageUrl}
                    />
                </div>
            ))}
        </div>
    );
}

function itemsArrayPush(listItem, count) {
    const items = [];
    for (const item in listItem) {
        let itemImages = listItem[item];
        let selectCountImage = `src_${count === 0 ? 6 : count}`;
        items.push(itemImages[selectCountImage]);
    }

    return items;
}

export function PaginationElement({
                                          perPage,
                                          listPreviewImage,
                                          selectSectionCount,
                                          getUrlSelectItem
                                      }) {
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);


    useEffect(() => {
        if (listPreviewImage && typeof perPage !== 'undefined') {
            let items = itemsArrayPush(listPreviewImage, selectSectionCount);
            const endOffset = itemOffset + perPage;
            const slicedItems = items.slice(itemOffset, endOffset);
            setCurrentItems(slicedItems);
            setPageCount(Math.ceil(items.length / perPage));
        }
        // Fetch items from another resources.
    }, [listPreviewImage, selectSectionCount, itemOffset, perPage]);

    const handlePageClick = (event) => {
        const newOffset = event.selected * perPage;
        setItemOffset(newOffset);
    };

    return (
        <div className="PaginationContainer">
            <Items currentItems={currentItems} getUrlSelectItem={getUrlSelectItem} />
            <ReactPaginate
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="PaginationPageList"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </div>
    );
}


const container = document.getElementById('listWheelPagination');
if (container) {
    ReactDOM.render(
        <PaginationElement perPage={4} getUrlSelectItem={handleGetUrlSelectItem} />,
        container
    );
}

function handleGetUrlSelectItem(imageUrl) {
    // console.log('Отримано imageUrl:', imageUrl);
}
