import { React,useState,useCallback,StatePool,BrowserRouter,Routes,Route} from './imports/import';
import {HeaderComponents, LandTopic, LandConstructorDownloadFile, LandTitle, LandElementSection, LandButton, LandPopup, Navbar, CreateLand} from './imports/import-components';
import {AppStyle} from './imports/import-classes';

const OneLinkOffer = StatePool.createState('');
const TrackerSelected = StatePool.createState('');
const OneLinkOfferUtmLink = StatePool.createState('');
const NameLandArchive = StatePool.createState('');
const OneLinkLand = StatePool.createState('show');

function App() {
    // const [username, setUsername] = useState('');
    // const [password, setPassword] = useState('');
    // const [isAuthenticated, setIsAuthenticated] = useState(false);
    //
    // const handleUsernameChange = (event) => {
    //     setUsername(event.target.value);
    // };
    //
    // const handlePasswordChange = (event) => {
    //     setPassword(event.target.value);
    // };
    //
    // const handleLogin = () => {
    //     // Відправте дані для авторизації на серверну частину (backend)
    //     // наприклад, використовуючи AJAX запит або бібліотеку axios
    //
    //     // Ось деякі приклади використання axios для відправки запиту на сервер:
    //     axios.post('http://localhost:8000/auth/login', { username, password })
    //         .then(response => {
    //             if (response.data.authenticated) {
    //                 setIsAuthenticated(true);
    //             } else {
    //                 alert('Невірний логін або пароль');
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Помилка авторизації', error);
    //         });
    // };

    const [topicDataNameLand, setTopicDataNameLand] = useState();
    const handleTopicDataMameLand = (data) => setTopicDataNameLand(data);
    const [imageData, setImageData] = useState(null);
    const handleImageData = useCallback((data) => {
        setImageData(data);
    }, []);
    const [pageLogoPosition, setPageLogoPosition] = useState();
    const handlePageLogoPosition = (data) => setPageLogoPosition(data);
    const [titleData, setTitleData] = useState({'titleTextData': {objTitleParameters: null} });
    const handleTitleData = (data) => setTitleData(data);
    const [titleText, setTitleText] = useState();
    const handleTitleText = (newValue) => setTitleText(newValue);
    const [textButton, setTextButton] = useState('Spin');
    const handleTextButton = (newValue) => setTextButton(newValue);
    const [buttonDataStyle, setButtonDataStyle] = useState({'buttonDataStyle': {objTitleParameters: null} })
    const handleButtonDataStyle = (data) => setButtonDataStyle(data);
    const [titlePopup, setTitlePopup] = useState()
    const handleTitlePopup = (titlePopup) => setTitlePopup(titlePopup);
    const [subTitlePopup, setSubTitlePopup] = useState();
    const handleSubTitlePopup = (subTitlePopup) => setSubTitlePopup(subTitlePopup);
    const [buttonTextPopup, setButtonTextPopup] = useState();
    const handleButtonTextPopup = (buttonTextPopup) => setButtonTextPopup(buttonTextPopup);
    const [popupTextData, setPopupTextData] = useState({titleData: {'fontSize': '',
                'colorText': '',
                'styleText': '',
                'textUppercase': '',
                'textUnderline': '',
                'positionText':'',}, subTitleData: {'fontSize': '',
                    'colorText': '',
                    'styleText': '',
                    'textUppercase': '',
                    'textUnderline': '',
                    'positionText':'',}, buttonTextData: {'fontSize': '',
                    'colorText': '',
                    'styleText': '',
                    'textUppercase': '',
                    'textUnderline': '',
                    'positionText':'',},});
    const handlePopupTextData = (data) => {
        setPopupTextData((prevData) => ({
            ...prevData,
            ...data,
        }));
    };
    const [colorTextOnWheel, setColorTextOnWheel] = useState();
    const handleColorTextOnWheel = (color) => setColorTextOnWheel(color);
    const [sectionDataWheel, setSectionDataWheel] = useState([]);
    const handleSectionData = (sectionData) => setSectionDataWheel(sectionData);
    const [countSection, setCountSection] = useState();
    const handleCountSection = (count) => setCountSection(count);
    const [newLandWheel, getNewLandWheel] = useState(null);
    const handleNewLandWheel = (data) => getNewLandWheel(data);
    const [typeLand, setTypeLand] = useState();
    const handleTypeLand = (data) => {
        setTypeLand(data);
    }
    const [linkOffer, setLinkOffer] = useState();
    const handleLinkToOffer = (data) => {
        setLinkOffer(data);
    }
    const [oneLinkOffer, setOneLinkOffer] = StatePool.useState(OneLinkOffer);
    const handleOneLinkOffer = (data) => {
        if (data) {
            setOneLinkOffer(data)
        }
    }
    const [wheelTextFontSize, setWheelTextFontSize] = useState();
    const handleWheelTextFontSize = (data) => {
        setWheelTextFontSize(data);
    }

    const [typeTracker, setTypeTracker] = useState();
    const handleTypeTracker = (data) => {
        setTypeTracker(data);
    }

    const [oneLinkUtmLink, setOneLinkUTMLink] = StatePool.useState(OneLinkOfferUtmLink);
    const handleOneLinkUTMLink = (data) => {
        setOneLinkUTMLink(data);
    }
    console.log(oneLinkUtmLink);

    const [typeGenerateLand, setTypeGenerateLand] = StatePool.useState(OneLinkLand)
    const handleTypeGenerateLand = (data) => {
        setTypeGenerateLand(data);
    }

    const [archiveLandNameDownload, setArchiveLandNameDownload] = StatePool.useState(NameLandArchive);
    const handleArchiveName = (data) => {
        setArchiveLandNameDownload(data);
    }



    // if (isAuthenticated) {
        return (
            <div className="App">
                {/*<LoginPage />*/}
                <HeaderComponents />
                <div className="MainContainer">
                    <div className="createLandSide">
                        <BrowserRouter>
                            <Navbar />
                            <div className="showContentEdit">
                                <Routes>
                                    {/*<Route path="/landtype"*/}
                                    {/*       element={<LandType />}*/}
                                    {/*/>*/}

                                    <Route exact path="/topic"
                                           element={<LandTopic
                                               getNameLandPreview={handleTopicDataMameLand}
                                               setTypeLandUpload={handleTypeLand}
                                               linkToOneLink={handleOneLinkOffer}
                                               getUtmLinkOneLink={handleOneLinkUTMLink}
                                               getTypeGenerateLand={handleTypeGenerateLand}
                                           />}
                                    />
                                    <Route path="/downloadsfile"
                                           element={<LandConstructorDownloadFile
                                               onImageData={handleImageData}
                                               sideLogoPosition={handlePageLogoPosition}
                                               dataLogo={!imageData ? '' : imageData}
                                           />}
                                    />
                                    <Route path="/landtitle"
                                           element={<LandTitle
                                               onChange={handleTitleText}
                                               onTextData={handleTitleData}
                                               titleText={!titleText ? '' : titleText}
                                               titleData={!titleData.fontSize ? '' : titleData.fontSize}
                                           />}
                                    />
                                    <Route path="/landelementsection"
                                           element={<LandElementSection
                                               getSectionText={handleSectionData}
                                               getCountSection={handleCountSection}
                                               getNewWheelUrl={handleNewLandWheel}
                                               colorTextOnWheel={handleColorTextOnWheel}
                                               getWheelTextFontSize={handleWheelTextFontSize}
                                           />}
                                    />
                                    <Route path="/button"
                                           element={<LandButton
                                               onChangeTextButton={handleTextButton}
                                               onButtonData={handleButtonDataStyle}
                                               titleData={!buttonDataStyle ? '' : buttonDataStyle}
                                           />}
                                    />
                                    <Route path="/popup"
                                           element={<LandPopup
                                               onTitlePopup={handleTitlePopup}
                                               onSubtitlePopup={handleSubTitlePopup}
                                               onButtonTextPopup={handleButtonTextPopup}
                                               onPopupDataObjectStyle={handlePopupTextData}
                                               popupData={!popupTextData ? '' : popupTextData}
                                               typeLandForUser={typeLand}
                                               typeTracker={handleTypeTracker}
                                               linkToOffer={handleLinkToOffer}
                                               archiveNameLand={handleArchiveName}
                                               checkLandGenerate={typeGenerateLand}
                                           />}
                                    />
                                </Routes>
                            </div>
                        </BrowserRouter>
                    </div>
                    <div className="preview" style={{position: 'sticky', top: 0}}>

                        <CreateLand
                            topicDataNameLand={topicDataNameLand}
                            logoData={imageData}
                            setPageLogoPosition={pageLogoPosition}
                            titleText={titleText}
                            siteTitleData={titleData}
                            sectionWheelText={sectionDataWheel}
                            setCountSection={countSection}
                            setNewWheel={newLandWheel}
                            textButtonLand={textButton}
                            onButtonData={buttonDataStyle}
                            popupTitle={titlePopup}
                            popupSubTitle={subTitlePopup}
                            popupButtonText={buttonTextPopup}
                            popupTextData={popupTextData}
                            colorTextWheel={!colorTextOnWheel ? '' : colorTextOnWheel}
                            linkToOffer={linkOffer}
                            // oneLinkOffer={oneLinkOffer}
                            setWheelTextFontSize={wheelTextFontSize}
                            typeTrackerSelected={typeTracker}
                            oneLinkOffer={oneLinkUtmLink}
                            nameArchiveLand={archiveLandNameDownload}
                            checkLandGenerate={typeGenerateLand}
                        />
                    </div>
                </div>
            </div>
        );
    // }
  //   return (
  //       <div>
  //           <h2>Форма для входу</h2>
  //           <div>
  //               <label htmlFor="username">Логін:</label>
  //               <input
  //                   type="text"
  //                   id="username"
  //                   name="username"
  //                   value={username}
  //                   onChange={handleUsernameChange}
  //               />
  //           </div>
  //           <div>
  //               <label htmlFor="password">Пароль:</label>
  //               <input
  //                   type="password"
  //                   id="password"
  //                   name="password"
  //                   value={password}
  //                   onChange={handlePasswordChange}
  //               />
  //           </div>
  //           <button onClick={handleLogin}>Увійти</button>
  //       </div>
  // );
}

export default App;
