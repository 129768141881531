import React from 'react';

const InlineElementStyleLogo = ({ logoStyle, logoPositionStyle }) => {
    // Перевіряємо, чи є зображення перед відображенням <img>
    if (logoStyle.image && logoStyle.image.image) {
        return (
            <img
                src={logoStyle.image.image}
                style={{
                    width: `${Number(logoStyle.width)}px`,
                    height: `${Number(logoStyle.height)}px`,
                }}
                alt=""
            />
        );
    } else {
        // Якщо зображення не завантажено, повертаємо null, щоб нічого не виводити
        return null;
    }
};

export default InlineElementStyleLogo;
