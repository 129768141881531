import React from 'react';
import classes from './MyInput.module.css';

const MyInput = ({children, getValueInput, setValueSaveState, ...props}) => {
    const handleValueInput = (event) => getValueInput(event.target.value);
    return (
        <input type="text" onChange={handleValueInput} className={classes.MyInput} {...props}/>
    );
};

export default MyInput;