import {React, useState, useEffect, StatePool } from '../../../imports/import';
import {MyInput, ColorPicker, PaginationElement} from '../../../imports/import-components';
import {LandElementSectionStyle} from '../../../imports/import-classes';
import PreviewImages from './PreviewImages.json';


const CountSelectSection = StatePool.createState(0);
const TextColorOnWheel = StatePool.createState('#000000');
const ArrayWheelDataText = StatePool.createState([]);
const FontSizeWheel = StatePool.createState('');

const LandElementSection = ({
                                getSectionText,
                                getCountSection,
                                getNewWheelUrl,
                                colorTextOnWheel,
                                getWheelTextFontSize
}) => {
    const [sectionCount, setSectionCount] = StatePool.useState(CountSelectSection);
    const [dataSection, setDataSection] = StatePool.useState(ArrayWheelDataText);
    const [previewImages, setPreviewImages] = useState();
    const [colorWheelText, setColorWheelText] = StatePool.useState(TextColorOnWheel);
    const [fontSizeWheel, setFontSizeWheel] = StatePool.useState(FontSizeWheel);

    const handleSelectItemUrl = (data) => getNewWheelUrl(data);
    const handleSectionCountChange = (event) => {
        const count = parseInt(event.target.value);
        setSectionCount(count);
        setDataSection([]);
        getCountSection(count);
    };
    const handleDataEditSection = (event, index) => {
        const value = event.target.value;
        setDataSection(prevData => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], valueData: value };
            getSectionText(newData); // Оновлену версію `dataSection` передаємо сюди
            return newData;
        });
    };
    const handleGetColorText = (data) => {
        setColorWheelText(data);
    }
    const handleFontSizeWheel = (data) => {
        setFontSizeWheel(data);
    }

    const renderEditSections = () => {
        const itemsToRender = [];
        for (let i = 0; i < sectionCount; i++) {
            const section = dataSection[i] || { keyDataName: i + 1, valueData: '' };
            itemsToRender.push(
                <div key={i} className={LandElementSectionStyle.itemEdit}>
                    <p className={LandElementSectionStyle.countSectorTitle}>{i === 0 ? 'Виграшний сектор' : `${i + 1}. сектор`}</p>
                    <MyInput
                        placeholder="Ваш текст"
                        onChange={(e) => handleDataEditSection(e, i)}
                        value={section.valueData}
                    />
                </div>
            );
        }
        return itemsToRender;
    };

    function handleRadioChange (event) {
        const selectedValue = event.target.value;
        setSectionCount(selectedValue);
    }

    useEffect(()=> {
        setPreviewImages(PreviewImages);
    }, [])
    useEffect(() => {
        colorTextOnWheel(colorWheelText);
    }, [colorWheelText]);
    useEffect(() => {
        getWheelTextFontSize(fontSizeWheel);
    }, [fontSizeWheel])
    return (
        <div className="tabsContentContainer">
            <div className={LandElementSectionStyle.sectorsCount}>
                <p className="littleTitleSlogan">К-ть секторів</p>
                <div className={LandElementSectionStyle.countBoxesContainer}
                     onChange={handleSectionCountChange}>
                    <div className={LandElementSectionStyle.countBoxesItem}>
                        <p className={LandElementSectionStyle.countSectionTitle}>6</p>
                        <input
                            type="radio"
                            name="countElements"
                            className={LandElementSectionStyle.radioCheckCountSection}
                            checked={sectionCount === 6}
                            value="6"
                            onClick={handleSectionCountChange}
                            onChange={handleRadioChange}
                        />
                    </div>
                    <div className={LandElementSectionStyle.countBoxesItem}>
                        <p className={LandElementSectionStyle.countSectionTitle}>8</p>
                        <input
                            type="radio"
                            name="countElements"
                            className={LandElementSectionStyle.radioCheckCountSection}
                            checked={sectionCount === 8}
                            value="8"
                            onClick={handleSectionCountChange}
                            onChange={handleRadioChange}
                        />
                    </div>
                    <div className={LandElementSectionStyle.countBoxesItem}>
                        <p className={LandElementSectionStyle.countSectionTitle}>12</p>
                        <input
                            type="radio"
                            name="countElements"
                            className={LandElementSectionStyle.radioCheckCountSection}
                            checked={sectionCount === 12}
                            value="12"
                            onClick={handleSectionCountChange}
                            onChange={handleRadioChange}
                        />
                    </div>
                </div>
            </div>
            <div className={LandElementSectionStyle.WheelStyle}>
                <p className="littleTitleSlogan">Стиль колеса</p>
                <div className="listWheelPagination" id="listWheelPagination"></div>
               <PaginationElement
                   listPreviewImage={previewImages}
                   selectSectionCount={sectionCount}
                   perPage={4}
                   getUrlSelectItem={handleSelectItemUrl}
                   />
            </div>

            <div className={LandElementSectionStyle.sectorsEdit}>
                <div className={LandElementSectionStyle.EditColorContainer}>
                    <p className="littleTitleSlogan">Виберіть колір тексту: </p>
                    <div className={LandElementSectionStyle.ColorPeaker}>
                        <ColorPicker
                            getColor={handleGetColorText}
                        />
                    </div>
                </div>
                <div className={LandElementSectionStyle.EditFontSize}>
                    <p className="littleTitleSlogan">Розмір тексу на колесі: </p>
                    <MyInput
                        onChange={(event) => handleFontSizeWheel(event.target.value)}
                        value={fontSizeWheel}
                        placeholder="Розмір тексту"
                    />
                </div>
                <p className="littleTitleSlogan">Заповніть бонуси</p>

                <div className={LandElementSectionStyle.listEditSectors}>
                    {renderEditSections()}
                </div>
            </div>
        </div>
    );
};

export default LandElementSection;
