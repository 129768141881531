import React from 'react';
// eslint-disable-next-line
import style from './style/style.css';
import Default_6 from './images/default_6.png';
import InlineElementStyleLogo from '../../UI/InlineElementStyle/InlineElementStyleLogo';
import AddTextToWheel from "../../../components_site/AddTextToWheel";

const Land4 = ({styleLandObject}) => {

  function letsSpin() {
    var x = 1024;
    var y = 9999;
    var deg = Math.floor(Math.random() * (x - y)) + y;

    document.getElementById('wheel').style.transform = styleLandObject.countSectionWheel === 6 ? "rotate(3550deg)" : "rotate(3850deg)";
    document.getElementById('wheel_list').style.transform = styleLandObject.countSectionWheel === 6 ? "rotate(3550deg)" : "rotate(3850deg)";
    setTimeout(()=>{showPopup()}, 4500);
  }

  function wheelShowPage () {
    if (!styleLandObject.getNewWheel) {
      return Default_6;
    } else {
      return require(`../../../assets/${styleLandObject.getNewWheel}`)
    }
  }

  function showPopup(){
    const popupElements = document.getElementById('popup');
    popupElements.style.display = 'flex';
  }

  return <div className="container land_4">
          <div className="container__logo" style={{justifyContent: styleLandObject.positionLogo === undefined ? false : styleLandObject.positionLogo}} >
            <InlineElementStyleLogo logoStyle={styleLandObject.siteLogo === undefined ? false : styleLandObject.siteLogo} />
          </div>
            <div className="container__text">
              <p className="top_text" style={{
                fontSize: styleLandObject.siteTitle.fontSize ? styleLandObject.siteTitle.fontSize: '32px',
                color: styleLandObject.siteTitle.colorText,
                fontStyle: styleLandObject.siteTitle.styleText,
                textTransform: styleLandObject.siteTitle.textUppercase,
                textDecoration: styleLandObject.siteTitle.textUnderline,
                fontWeight: styleLandObject.siteTitle.fontWeight,
                textAlign: styleLandObject.siteTitle.positionText
              }}>{!styleLandObject.titleText ? "Bono de bienvenida 100% hasta 500 + 200 FS" : styleLandObject.titleText}</p>
            </div>

            <div className="container__wheel">
              <div className="middle">
                <div className="wheel_container">
                  <img id="wheel" className="img_middle" src={wheelShowPage()} alt=""/>
                </div>
                <AddTextToWheel
                    setSectionWheel={!styleLandObject.countSectionWheel === true ? 6 : styleLandObject.countSectionWheel }
                    textWheelData={styleLandObject.sectionWheelDataText}
                    colorText={!styleLandObject.colorTextWheel ? '' : styleLandObject.colorTextWheel}
                    fontSizeTextWheel={styleLandObject.wheelTextFontSize}
                />
              </div>
            </div>

            <div className="container__btn" onClick={letsSpin}>
              <p className="btn_text" style={{
                fontSize: styleLandObject.buttonTextData.fontSize ? styleLandObject.buttonTextData.fontSize: '32px',
                color: styleLandObject.buttonTextData.colorText,
                fontStyle: styleLandObject.buttonTextData.styleText,
                textTransform: styleLandObject.buttonTextData.textUppercase,
                textDecoration: styleLandObject.buttonTextData.textUnderline
              }}>{!styleLandObject.textButton ? "SPIN" : styleLandObject.textButton}</p>
            </div>

            <div className="popup" id="popup">
              <div className="popup__container">
                <div className="popup__title">
                  <p className="popup_title" style={{
                    fontSize: styleLandObject.dataPopupText.titleData.fontSize ? styleLandObject.dataPopupText.titleData.fontSize: '22px',
                    color: styleLandObject.dataPopupText.titleData.colorText,
                    fontStyle: styleLandObject.dataPopupText.titleData.styleText,
                    textTransform: styleLandObject.dataPopupText.titleData.textUppercase,
                    textDecoration: styleLandObject.dataPopupText.titleData.textUnderline
                  }}>{!styleLandObject.textTitlePopup ? "Bono de bienvenida" : styleLandObject.textTitlePopup}</p>
                </div>

                <div className="popup__content">
                  <p className="popup_content" style={{
                    fontSize: styleLandObject.dataPopupText.subTitleData.fontSize ? styleLandObject.dataPopupText.subTitleData.fontSize: '18px',
                    color: styleLandObject.dataPopupText.subTitleData.colorText,
                    fontStyle: styleLandObject.dataPopupText.subTitleData.styleText,
                    textTransform: styleLandObject.dataPopupText.subTitleData.textUppercase,
                    textDecoration: styleLandObject.dataPopupText.subTitleData.textUnderline
                  }}>{!styleLandObject.textSubTitlePopup ? "Bono de bienvenida 100% hasta 500 + 200 FS" : styleLandObject.textSubTitlePopup}</p>
                </div>

                <div className="popup__btn">
                    <p className="popup_btn_text" style={{
                      fontSize: styleLandObject.dataPopupText.buttonTextData.fontSize ? styleLandObject.dataPopupText.buttonTextData.fontSize: '22px',
                      color: styleLandObject.dataPopupText.buttonTextData.colorText,
                      fontStyle: styleLandObject.dataPopupText.buttonTextData.styleText,
                      textTransform: styleLandObject.dataPopupText.buttonTextData.textUppercase,
                      textDecoration: styleLandObject.dataPopupText.buttonTextData.textUnderline
                    }}>{!styleLandObject.textButtonPopup ? "get bonus" : styleLandObject.textButtonPopup}</p>
                </div>
              </div>
            </div>
          </div>
};

export default Land4;