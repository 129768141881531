import {React, useState } from '../../../imports/import';
import {ImageLoaded} from '../../../imports/import-classes'
const ImageUploader = ({ onImagesParameters }) => {
    // eslint-disable-next-line
    const [image, setImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            const uploadedImage = reader.result;
            setImage(uploadedImage);
            const img = new Image();
            img.src = uploadedImage;
            img.onload = () => {
                const imageParams = { image: uploadedImage, width: img.width, height: img.height };
                onImagesParameters(imageParams);
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className={ImageLoaded.ContainerLoadImage}>
            <input type="file" className={ImageLoaded.inputLoadImage} onChange={handleImageChange} />
        </div>
    );
};

export default ImageUploader;
