import {React, useState,  useEffect, useCallback, StatePool} from '../../../imports/import';
import {LoadFileStyle} from '../../../imports/import-classes';
import {ImageUpload} from '../../../imports/import-components';
import {LeftSideImageActive, LeftSide, Center, CenterActive, RightSide, RightSideActive} from '../../../imports/import-images';

const activeLogoPositionOnPage = StatePool.createState({logoPagePosition: 'center'});
const activeLogoStylePosition = StatePool.createState({activeLogoStylePosition: {position: 'unset',left: 'unset',transform: 'unset'}})
const LogoWidth = StatePool.createState(100);
const LogoHeight = StatePool.createState(80);

const LandConstructorDownloadFile = ({
                                         onImageData,
                                         sideLogoPosition
}) => {
    const [image, setImage] = useState(null);
    const [width, setWidth] = StatePool.useState(LogoWidth);
    const [height, setHeight] = StatePool.useState(LogoHeight);
    const [logoSidePosition, setLogoSidePosition] = StatePool.useState(activeLogoStylePosition);
    const [activePositionLogo, setActivePositionLogo] = StatePool.useState(activeLogoPositionOnPage);
    function logoPosition (sidePosition) {
        const RightPosition = 'right';
        const CenterPosition = 'center';
        const LeftPosition = 'left';

        if (sidePosition === 'left') {
            setLogoSidePosition(LeftPosition);
        } else if (sidePosition === 'right') {
            setLogoSidePosition(RightPosition);
        } else {
            setLogoSidePosition(CenterPosition);
        }
        setActivePositionLogo(sidePosition)
    }

    const updateImageData = useCallback(() => {
        if (image) {
            const imageData = { image, width, height };
            onImageData(imageData);
        }
    }, [image, width, height, onImageData]);

    useEffect(() => {
        updateImageData();
    }, [updateImageData]);

    const handleImageUpload = (uploadedImage) => {
        setImage(uploadedImage);
    };

    const handleWidthChange = (event) => {
        const widthValue = event.target.value;
        setWidth(widthValue);
    };

    const handleHeightChange = (event) => {
        const heightValue = event.target.value;
        setHeight(heightValue);
    };

    function checkActiveLogoPosition(event) {
        let parentElement = event.target.parentNode;
        let childrenMainContainer = parentElement.parentNode.childNodes;
        for (let i = 0; i < childrenMainContainer.length; i++) {
            childrenMainContainer[i].classList.remove(`${LoadFileStyle.active}`)
        }
        parentElement.classList.toggle(`${LoadFileStyle.active}`);
        logoPosition(event.target.value);
    }

    useEffect(() => {
        sideLogoPosition(logoSidePosition);
    }, [activePositionLogo])

    return (
        <div className="tabsContentContainer">
            <div className={LoadFileStyle.formLoadFileLand}>
                <form className={LoadFileStyle.loadFIle}>
                    <div className={LoadFileStyle.form_block}>
                        <p className={LoadFileStyle.labelToInput}>Лого</p>
                        <ImageUpload
                            onImagesParameters={handleImageUpload} />
                    </div>
                </form>
            </div>

            <div className={LoadFileStyle.editLogo}>
                <p className={LoadFileStyle.editLogo__title}>Параметри лого</p>
                <div className={LoadFileStyle.sizeLogoBlock}>
                    <div className={LoadFileStyle.sizeLogoBlock__item}>
                        <p className={LoadFileStyle.sizeLogoBlock__itemTitle}>Висота, px</p>
                        <input type="text"
                               className={LoadFileStyle.sizeLogoBlock__input}
                               onChange={handleHeightChange}
                               value={height}
                               placeholder="100" />
                    </div>
                    <div className={LoadFileStyle.sizeLogoBlock__item}>
                        <p className={LoadFileStyle.sizeLogoBlock__itemTitle}>Ширина, px</p>
                        <input type="text"
                               className={LoadFileStyle.sizeLogoBlock__input}
                               onChange={handleWidthChange}
                               placeholder="250"
                               value={width} />
                    </div>
                </div>

                <p className={LoadFileStyle.titleSizeBlock}>Розміщення</p>
                <div className={LoadFileStyle.positionLogoContainer}
                     onClick={checkActiveLogoPosition}>
                    <div
                        className={`${LoadFileStyle.positionLogoItem} 
                        ${activeLogoPositionOnPage.value === 'left' ? LoadFileStyle.active : ''}`} >
                        <input
                            type="radio"
                            className="left radioPosition"
                            name="position"
                            value="left" />
                        <img
                            src={LeftSide}
                            className={LoadFileStyle.positionImage}
                            alt=""/>
                        <img
                            src={LeftSideImageActive}
                            className={`${LoadFileStyle.positionImage} ${LoadFileStyle.activeImage}`}
                            alt=""/>
                    </div>
                    <div
                        className={`${LoadFileStyle.positionLogoItem} 
                        ${activeLogoPositionOnPage.value === 'center' ? LoadFileStyle.active : ''}`}>
                        <input
                            type="radio"
                            className="center radioPosition"
                            name="position"
                            value="center" />
                        <img
                            src={Center}
                            className={LoadFileStyle.positionImage}
                            alt=""/>
                        <img
                            src={CenterActive}
                            className={`${LoadFileStyle.positionImage} ${LoadFileStyle.activeImage}`}
                            alt=""/>
                    </div>
                    <div
                        className={`${LoadFileStyle.positionLogoItem} ${activeLogoPositionOnPage.value === 'right' ? LoadFileStyle.active : ''}`}>
                        <input
                            type="radio"
                            className="right radioPosition"
                            name="position"
                            value="right" />
                        <img
                            src={RightSide}
                            className={LoadFileStyle.positionImage}
                            alt=""/>
                        <img
                            src={RightSideActive}
                            className={`${LoadFileStyle.positionImage} ${LoadFileStyle.activeImage}`}
                            alt=""/>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default LandConstructorDownloadFile;