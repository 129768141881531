import {React,useState, useEffect, StatePool}  from '../../../imports/import';
import {EditText, TitleElement, MyInput} from '../../../imports/import-components';
import {LandPopupStyle} from '../../../imports/import-classes';

const PopupTitle = StatePool.createState('');
const PopupSubTitle = StatePool.createState('');
const PopupButtonText = StatePool.createState('');
const SelectTracker = StatePool.createState('');
const NameGenerateLand = StatePool.createState('');

const LandPopup = ({
                           onTitlePopup,
                           onSubtitlePopup,
                           onButtonTextPopup,
                           onPopupDataObjectStyle,
                           popupData,
                           typeLandForUser,
                           typeTracker,
                           archiveNameLand,
                           checkLandGenerate
                       }) => {

    const [popupTitleText, setPopupTitleText] = StatePool.useState(PopupTitle);
    const [subTitlePopupText, setSubTitlePopupText] = StatePool.useState(PopupSubTitle);
    const [buttonPopupText, setButtonPopupText] = StatePool.useState(PopupButtonText);
    const [selectedTracker, setSelectedTracker] = StatePool.useState(SelectTracker)
    const [nameSendLand, setNameSendLand] = StatePool.useState(NameGenerateLand)

    const handleNameSendLand = (data) => {
        setNameSendLand(data)
    }
    const handleTrackerSelected = (event) => {
        let tracker = event.target.value;
        console.log(tracker, 'tracker')
        setSelectedTracker(tracker);
    }
    const handlePopupTitleText = (data) => {
        setPopupTitleText(data)
    }
    const handleSubTitlePopupText = (data) => {
        setSubTitlePopupText(data);
    }
    const handleButtonPopupText = (data) => {
        setButtonPopupText(data)
    }
    const handleTitleTextData = (data) => {
        onPopupDataObjectStyle({ titleData: data });
    };
    const handleSubTitleTextData = (data) => {
        onPopupDataObjectStyle({ subTitleData: data });
    };
    const handleButtonTextData = (data) => {
        onPopupDataObjectStyle({ buttonTextData: data });
    };

    function handleRadioChange (event) {
        const selectedValue = event.target.value;
        setSelectedTracker(selectedValue);
    }


    useEffect(() => {
        onTitlePopup(popupTitleText);
    },[popupTitleText])
    useEffect(() => {
        onSubtitlePopup(subTitlePopupText);
    },[subTitlePopupText])
    useEffect(() => {
        onButtonTextPopup(buttonPopupText);
    },[buttonPopupText])
    useEffect(() => {
        typeTracker(selectedTracker)
    }, [selectedTracker])
    useEffect(() => {
        archiveNameLand(nameSendLand)
    }, [nameSendLand]);
    return (
        <div className="tabsContentContainer">
            <div className={LandPopupStyle.inputText}>
                <TitleElement titleText="Заголовок попапа" />
                <MyInput
                    onChange={(event) => handlePopupTitleText(event.target.value)}
                    placeholder="Текст заголовку"
                    value={PopupTitle.value}
                />
                <EditText
                    onTextData={handleTitleTextData}
                    hidePositionText={true}
                    dataSave={popupData}
                />
            </div>
            <div className={LandPopupStyle.inputText}>
                <TitleElement titleText="Текст виграшу" />
                <MyInput
                    onChange={(event) => handleSubTitlePopupText(event.target.value)}
                    placeholder="Текст виграшу"
                    value={PopupSubTitle.value}
                />
                <EditText
                    onTextData={handleSubTitleTextData}
                    hidePositionText={true}
                    dataSave={popupData}
                />
            </div>
            <div className={LandPopupStyle.inputText}>
                <TitleElement titleText="Текст на кнопці" />
                <MyInput
                    onChange={(event) => handleButtonPopupText(event.target.value)}
                    placeholder="Текст кнопки"
                    value={PopupButtonText.value}
                />
                <EditText
                    onTextData={handleButtonTextData}
                    hidePositionText={true}
                    dataSave={popupData}
                />
            </div>
            <div
                className={LandPopupStyle.NameLand}
                style={{display: checkLandGenerate === 'show' ? 'block' : 'none'}}
            >
                <TitleElement titleText="Назва лендінгу" />
                <MyInput
                    onChange={(event) => handleNameSendLand(event.target.value)}
                    placeholder="NameLanding_Zeus_AU"
                    value={nameSendLand.value}
                />
            </div>
            <div
                className={LandPopupStyle.inputText}
                style={{display: checkLandGenerate === 'show' ? 'block' : 'none'}}
            >
                <TitleElement titleText="Виберіть свій трекер" />
                <div className={LandPopupStyle.CheckedTracker} >
                    <p className={LandPopupStyle.ItemTrackerCheck}>
                        <label htmlFor="keitaro">Keitaro</label>
                        <input
                            type="radio"
                            name="tracker_check"
                            value="keitaro"
                            id="keitaro"
                            onClick={handleTrackerSelected}
                            checked={selectedTracker === 'keitaro'}
                            onChange={handleRadioChange}
                        />
                    </p>
                    <p className={LandPopupStyle.ItemTrackerCheck}>
                        <label htmlFor="binom">Binom</label>
                        <input
                            type="radio"
                            name="tracker_check"
                            value="binom"
                            id="binom"
                            onClick={handleTrackerSelected}
                            checked={selectedTracker === 'binom'}
                            onChange={handleRadioChange}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LandPopup;