import React, {useState} from 'react';
import {Link} from "react-router-dom";
import classes from './Navbar.module.scss';

const Navbar = () => {

    const [activeItem, setActiveItem] = useState('landtype');

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
    };

    return (
        <div className={classes.navBarElement}>
            <ul className={classes.listLink}>
                {/*<li className={classes.listLinkItem}>*/}
                {/*    <Link*/}
                {/*        className={activeItem === 'landtype' ? classes.active : ''}*/}
                {/*        onClick={() => handleItemClick('landtype')}*/}
                {/*        to="/landtype">Тип ленда*/}
                {/*    </Link>*/}
                {/*</li>*/}
                <li className={classes.listLinkItem}>
                    <Link
                        className={activeItem === 'topic' ? classes.active : ''}
                        onClick={() => handleItemClick('topic')}
                        to="/topic">Тематика</Link>
                </li>
                <li className={classes.listLinkItem}>
                    <Link
                        className={activeItem === 'downloadsfile' ? classes.active : ''}
                        onClick={() => handleItemClick('downloadsfile')}
                        to="/downloadsfile">Завантажити</Link>
                </li>
                <li className={classes.listLinkItem}>
                    <Link
                        className={activeItem === 'landtitle' ? classes.active : ''}
                        onClick={() => handleItemClick('landtitle')}
                        to="/landtitle">Заголовок</Link>
                </li>
                <li className={classes.listLinkItem}>
                    <Link
                        className={activeItem === 'landelementsection' ? classes.active : ''}
                        onClick={() => handleItemClick('landelementsection')}
                        to="/landelementsection">Набір елементів</Link>
                </li>
                <li className={classes.listLinkItem}>
                    <Link
                        className={activeItem === 'button' ? classes.active : ''}
                        onClick={() => handleItemClick('button')}
                        to="/button">Кнопка</Link>
                </li>
                <li className={classes.listLinkItem}>
                    <Link
                        className={activeItem === 'popup' ? classes.active : ''}
                        onClick={() => handleItemClick('popup')}
                        to="/popup">Попап</Link>
                </li>
            </ul>
        </div>
    );
};

export default Navbar;